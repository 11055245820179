




































































































import {
  SfTabs,
  SfTable,
  SfButton,
  SfLink,
  SfPagination,
  SfSelect,
  SfLoader,
} from '@storefront-ui/vue';
import {
  ref,
  computed,
  defineComponent,
  useRoute,
  useFetch,
} from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import orderGetters from '~/modules/checkout/getters/orderGetters';
import { useUiHelpers } from '~/composables';
import { useUserOrder } from '~/modules/customer/composables/useUserOrder';
import type { CustomerOrders, CustomerOrder } from '~/modules/GraphQL/types';
import { OrderStatusEnum } from '~/modules/customer/enums/OrderStatusEnum';

export default defineComponent({
  name: 'OrderHistory',
  components: {
    LazyHydrate,
    SfButton,
    SfLink,
    SfPagination,
    SfSelect,
    SfTable,
    SfTabs,
    SfLoader,
  },
  setup() {
    const { search } = useUserOrder();
    const route = useRoute();
    const th = useUiHelpers();
    const {
      query: { page, itemsPerPage },
    } = route.value;

    const rawCustomerOrders = ref<CustomerOrders | null>(null);

    useFetch(async () => {
      rawCustomerOrders.value = await search({
        currentPage: Number.parseInt(page as string, 10) || 1,
        pageSize: Number.parseInt(itemsPerPage as string, 10) || 10,
      });
    });

    const tableHeaders = ['Order ID', 'Payment date', 'Amount'];

    const getStatusTextClass = (order: CustomerOrder) => {
      switch (order.status) {
        case OrderStatusEnum.OPEN:
          return 'text-warning';
        case OrderStatusEnum.COMPLETE:
          return 'text-success';
        default:
          return '';
      }
    };

    const pagination = computed(() =>
      orderGetters.getPagination(rawCustomerOrders.value),
    );

    return {
      getStatusTextClass,
      orderGetters,
      getDate: orderGetters.getDate,
      getPrice: orderGetters.getPrice,
      getCurrency: orderGetters.getCurrency,
      orders: computed(() => rawCustomerOrders.value?.items ?? []),
      rawCustomerOrders,
      pagination,
      tableHeaders,
      th,
    };
  },
});
